// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-customer-acquisition-js": () => import("./../../../src/pages/customer-acquisition.js" /* webpackChunkName: "component---src-pages-customer-acquisition-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-superior-merchants-js": () => import("./../../../src/pages/superior-merchants.js" /* webpackChunkName: "component---src-pages-superior-merchants-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-u-save-services-js": () => import("./../../../src/pages/u-save-services.js" /* webpackChunkName: "component---src-pages-u-save-services-js" */),
  "component---src-pages-your-repair-experts-js": () => import("./../../../src/pages/your-repair-experts.js" /* webpackChunkName: "component---src-pages-your-repair-experts-js" */)
}

